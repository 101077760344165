import React from "react";
// import axios from "axios";
import axios from "../config";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";

const LoginForm = ({ setLoggedIn }) => {
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.target;
    const username = form.elements.username.value;
    const password = form.elements.password.value;

    try {
      const response = await axios.post("/login", {
        username,
        password,
      }, {
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.data.success) {
        // console.log("Login successful, token:", response.data.accessToken);
        
        if (!response.data.accessToken) {
          throw new Error('No access token received');
        }

        localStorage.setItem("token", response.data.accessToken);
        console.log("Token saved in localStorage:", localStorage.getItem("token"));
        
        setLoggedIn(true);
        navigate("/leads");
      } else {
        console.error("Login failed:", response.data.error);
      }
    } catch (error) {
      console.error("Login error:", error);
      console.error("Error response:", error.response?.data);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <FloatingLabel
          controlId="floatingUsername"
          label="Username"
          className="mb-3"
        >
          <Form.Control
            type="text"
            placeholder="Enter username"
            name="username"
          />
        </FloatingLabel>
        <FloatingLabel
          controlId="floatingPassword"
          label="Password"
          className="mb-3"
        >
          <Form.Control
            type="password"
            placeholder="Enter password"
            name="password"
          />
        </FloatingLabel>
        <button className="primary-button" type="submit">
          Login
        </button>
      </form>
    </>
  );
};

export default LoginForm;
