import React, { useState } from "react";
import LoginForm from "../LoginForm";
import { useNavigate } from "react-router";

const AuthenticationPage = ({ setLoggedIn }) => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  if (token) {
    navigate("/leads");
    return;
  }

  return (
    <>
      <div className="authentication-page d-flex justify-content-center align-items-center">
        <div className="authentication-form-container">
          <h1 className="color-dark-grey-900 mb-4"> Login </h1>
          <LoginForm setLoggedIn={setLoggedIn} />
        </div>
      </div>
    </>
  );
};

export default AuthenticationPage;
